import React, { useEffect, useState } from 'react'
import '../styles/Grid.css'
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  increment,
  getDoc
} from 'firebase/firestore'
import { logEvent } from 'firebase/analytics'
import ChartIcon from '../chart.svg'
import { Keyboard } from './Keyboard'

export function Game2 ({
  score,
  setScore,
  app,
  setShowSqueeze,
  setHigh,
  copyToClipboard,
  analytics
}) {
  const [wordStart, setWordStart] = useState('wordss')
  const [lastWord, setLastWord] = useState('wordss')
  const [wordCurrent, setWordCurrent] = useState('wordss')
  const keyboard = ['qwertyuiop', 'asdfghjkl', 'zxcvbnm']
  const [active, setActive] = useState(0)
  const [changed, setChanged] = useState(false)
  const [pastWords, setPastWords] = useState([])
  const [error, setError] = useState(false)
  const [highscore, setHighscore] = useState('unsolved')
  const [players, setPlayers] = useState(0)
  const [todayId, setTodayId] = useState('0')
  const [showYesterday, setShowYesterday] = useState(false)
  const [yesterdayScore, setYesterdayScore] = useState(0)
  const [yesterdayArr, setYesterdayArr] = useState([])

  const data = require('../helpers/wordlist.js')
  const db = getFirestore(app)

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, 'Scores'), where('today', '==', true))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach(async doc => {
        let data = doc.data()
        setTodayId(doc.id)
        if (data.FivesHighScore) {
          setHighscore(data.FivesHighScore)
        }
        let start = data.fives
        setWordStart(start)
        setLastWord(start)
        setWordCurrent(start)
        setPastWords([start])
        setPlayers(data.fivesPlayers)

        var d = new Date(data.date)
        d.setDate(d.getDate() - 1)
        const q2 = query(
          collection(db, 'Scores'),
          where('date', '==', d.toLocaleDateString())
        )
        const querySnapshot2 = await getDocs(q2)
        querySnapshot2.forEach(doc => {
          let data = doc.data()
          if (data.bestFives && data.FivesHighScore) {
            setYesterdayArr(data.bestFives.reverse())
            setYesterdayScore(data.FivesHighScore)
          }
        })
      })
    }
    setChanged(false)
    setError(false)
    setScore(0)
    setHighscore(0)
    setPlayers(0)
    setActive(0)
    setPastWords(['wordss'])
    setWordStart('wordss')
    setLastWord('wordss')
    setWordCurrent('wordss')
    setTodayId('0')
    setYesterdayArr([])
    setYesterdayScore(0)

    fetchData()
  }, [])

  useEffect(() => setHigh(highscore), [highscore])

  function changeActive (val) {
    if (!error) {
      let word = wordCurrent
      word = word.slice(0, active) + val + word.slice(active + 1)
      setWordCurrent(word)
      setChanged(true)
    }
  }

  function undo () {
    setChanged(false)
    setWordCurrent(lastWord)
    setError(false)
  }

  async function win () {
    const docRef = doc(db, 'Scores', todayId)
    const docs = await getDoc(docRef)
    if (docs.exists()) {
      let high = docs.data().FivesHighScore
      let playerRef = docs.data().fivesPlayers
      if (high && score <= high) {
        setHighscore(high)
        setDoc(docRef, { fivesPlayers: increment(1) }, { merge: true })
      } else {
        setHighscore(score)
        setDoc(
          docRef,
          {
            FivesHighScore: score,
            fivesPlayers: increment(1),
            bestFives: pastWords
          },
          { merge: true }
        )
      }
      setPlayers(playerRef + 1)
    } else {
      setPlayers(players + 1)
      if (score > highscore) {
        setHighscore(score)
      }
    }
  }

  function check () {
    if (
      !pastWords.includes(wordCurrent) &&
      data.wordSet.has(wordCurrent.toUpperCase())
    ) {
      setChanged(false)
      setLastWord(wordCurrent)
      setPastWords([wordCurrent, ...pastWords])
      setError(false)
      setScore(score + 1)
    } else {
      setError(true)
      win()
      logEvent(analytics, 'finished_fives')
    }
  }

  function restart () {
    setChanged(false)
    setLastWord(wordStart)
    setWordCurrent(wordStart)
    setScore(0)
    setActive(0)
    setError(false)
    setPastWords([wordStart])
  }

  function share () {
    let text =
      "I just finsihed today's Fives with " +
      score +
      ' words! Can you beat me?\n' +
      getShareBoard() +
      ' \ngames.dbcmediaonline.com'
    if (highscore === score) {
      text =
        'High Fives! I just got the highscore so far in Fives with ' +
        score +
        ' words! Can you beat me?\n' +
        getShareBoard() +
        ' \ngames.dbcmediaonline.com'
    }
    logEvent(analytics, 'shareded_fives')
    navigator.share
      ? navigator.share({
          text: text
        })
      : navigator.clipboard.writeText(text) && copyToClipboard()
  }

  function getShareBoard () {
    let ret = ''
    let arr = pastWords.reverse()
    for (var i = 0; i < arr.length; i++) {
      ret += '\n'
      if (arr.length > i + 1) {
        for (var j = 0; j < 5; j++) {
          if (arr[i].charAt(j) === arr[i + 1].charAt(j)) {
            ret += String.fromCodePoint(0x0001f7e6)
          } else {
            ret += String.fromCodePoint(0x0001f7e9)
          }
        }
      }
    }
    for (var k = 0; k < 5; k++) {
      ret += String.fromCodePoint(0x0001f7e6)
    }
    ret += '\n'
    return ret
  }

  return (
    <div className={error ? 'game2 disablePointer' : 'game2'}>
      {!error && (
        <>
          {highscore === 'unsolved' ? (
            <div>
              <h3 className='m0'>Unsolved Today</h3>
            </div>
          ) : (
            <div>
              <h3 className='m0'>
                Played {players} time{players !== 1 ? 's' : ''} today!
              </h3>
            </div>
          )}
        </>
      )}
      {error && (
        <div>
          <h3 className='m0'>
            Highscore is {highscore} out of {players} Total Games!
          </h3>
        </div>
      )}
      {error && (
        <>
          {score < highscore ? (
            <div>
              <h3 className='lose'>
                You made {score} {score === 1 ? 'word' : 'words'}! Can you do
                better?
              </h3>
            </div>
          ) : (
            <div>
              <h3 className='win'>
                High Fives! You made the most words so far with {score}{' '}
                {score === 1 ? 'word' : 'words'}! Can you do better?
              </h3>
            </div>
          )}
        </>
      )}
      <div className={error ? 'error word' : 'word good'}>
        {wordCurrent.length === 5 && (
          <div className='actualWord'>
            {wordCurrent.split('').map((val, index) => (
              <div
                className={active === index ? 'CharsDiv active' : 'CharsDiv'}
                key={val + index}
                onClick={() => wordCurrent === lastWord && setActive(index)}
              >
                {val}
              </div>
            ))}
          </div>
        )}
        <div className='keyboard ml16px'>
          <div
            className={
              changed && !error && wordCurrent !== lastWord
                ? 'CharsDiv charButton won'
                : 'CharsDiv disable charButton won'
            }
            onClick={check}
          >
            Submit
          </div>
        </div>
      </div>

      {error && (
        <div className='shareButton'>
          <div className={'CharsDiv mustUndo charButton'} onClick={restart}>
            Restart
          </div>
          <div className={'CharsDiv charButton'} onClick={share}>
            Share
          </div>
          <div
            className={'CharsDiv charButton'}
            onClick={() => setShowSqueeze(true)}
          >
            Play Squeeze
          </div>

          {yesterdayScore !== 0 && (
            <div
              className={'CharsDiv charButton'}
              onClick={() => setShowYesterday(!showYesterday)}
            >
              <img className='icon' src={ChartIcon} alt="Yesterday's Score" />
            </div>
          )}
        </div>
      )}
      {!error && (
        <div className='keyboard'>
          <Keyboard changeAction={changeActive} />
          <div style={{ gap: '24px' }}>
            <div
              className={
                score === 0
                  ? 'CharsDiv disable charButton'
                  : 'CharsDiv mustUndo charButton'
              }
              onClick={restart}
            >
              Restart
            </div>
            <div
              className={
                changed ? 'CharsDiv charButton' : 'CharsDiv disable charButton'
              }
              onClick={undo}
            >
              Undo
            </div>

            {yesterdayScore !== 0 && (
              <div
                className={'CharsDiv charButton darkButton'}
                onClick={() => setShowYesterday(!showYesterday)}
              >
                <img className='icon' src={ChartIcon} alt="Yesterday's Score" />
              </div>
            )}
          </div>
        </div>
      )}
      <div>
        <ol reversed start={pastWords.length - 1}>
          {pastWords.map((val, index) => (
            <li key={val + index}>{val.toUpperCase()}</li>
          ))}
        </ol>
      </div>

      {showYesterday && (
        <div className='yesterdayFives'>
          <h4>Highest Score Yesterday: {yesterdayScore}</h4>
          {yesterdayArr.map((word, index) => (
            <div key={word + index}>
              {word.split('').map((val, index2) => (
                <div
                  className={
                    yesterdayArr.length - 1 !== index &&
                    val !== yesterdayArr[index + 1].charAt(index2)
                      ? 'CharsDiv active'
                      : 'CharsDiv'
                  }
                  key={word + index + val + index2}
                >
                  {val}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
