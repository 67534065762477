import React, { useState, useEffect } from 'react'
import { DndContext } from '@dnd-kit/core'
import { Draggable } from '../helpers/Draggable'
import { Droppable } from '../helpers/Droppable'
import '../styles/Grid.css'
import { getFunctions, httpsCallable } from 'firebase/functions'
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  increment,
  setDoc,
  arrayUnion,
  getDoc
} from 'firebase/firestore'
import { logEvent } from 'firebase/analytics'
import ChartIcon from '../chart.svg'
import CheckIcon from '../check.svg'
import ExpandIcon from '../expand.svg'
import RefreshIcon from '../refresh.svg'
import { Keyboard } from './Keyboard'

export function Game ({
  score,
  setScore,
  app,
  setShowSqueeze,
  setHigh,
  copyToClipboard,
  analytics
}) {
  const [chars, setChars] = useState([])

  const [parentMap, setParentMap] = useState(new Map())
  const [charsInGrid, setCharsInGrid] = useState([])
  const [testMap, setTestMap] = useState(new Map())
  const [charsInTest, setCharsInTest] = useState([])

  const maxrows = 11
  const minrows = 8
  const [numrows, setNumrows] = useState(8)
  const [highscore, setHighscore] = useState('Unsolved')
  const [players, setPlayers] = useState(0)
  const [scores, setScores] = useState([])

  const [yesterdayScore, setYesterdayScore] = useState(0)
  const [yesterdayArr, setYesterdayArr] = useState([])
  const [yesterdayMap, setYesterdayMap] = useState(new Map())
  const [showYesterday, setShowYesterday] = useState(false)

  const [alert, setAlert] = useState('')
  const [won, setWon] = useState(false)
  const [checking, setChecking] = useState(false)

  const [wordIsGood, setWordIsGood] = useState(false)

  const [todayId, setTodayId] = useState('0')

  const [showWordChecker, setShowWordChecker] = useState(false)

  const functions = getFunctions(app)
  const db = getFirestore(app)

  const dataSet = require('../helpers/wordlist.js')

  const [real, setReal] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, 'Scores'), where('today', '==', true))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach(async doc => {
        let data = doc.data()
        setTodayId(doc.id)
        if (data.HighScore) {
          setHighscore(data.HighScore)
        }
        setScores(data.scores)
        setPlayers(data.players)
        let joined = data.chars
        joined = joined.split('')
        setChars(shuffleArray(joined))

        var d = new Date(data.date)
        d.setDate(d.getDate() - 1)
        const q2 = query(
          collection(db, 'Scores'),
          where('date', '==', d.toLocaleDateString())
        )
        const querySnapshot2 = await getDocs(q2)
        querySnapshot2.forEach(doc => {
          let data = doc.data()
          if (data.bestSqueeze && data.HighScore) {
            setYesterdayArr(JSON.parse(data.bestSqueeze))
            setYesterdayScore(data.HighScore)
          }
        })
      })
    }
    setCharsInGrid([])
    setParentMap(new Map())
    setNumrows(minrows)
    setAlert('')
    setWon(false)
    setChecking(false)

    setTestMap(new Map())
    setCharsInTest([])
    setScore(0)
    setWordIsGood(false)

    setYesterdayArr([])
    setYesterdayMap(new Map())
    setYesterdayScore(0)

    setTodayId('0')
    setReal([])

    fetchData()
  }, [])

  useEffect(() => setHigh(highscore), [highscore])

  useEffect(() => {
    if (yesterdayArr.length > 0) {
      var map = new Map()
      for (var i = 0; i < yesterdayArr.length; i++) {
        for (var j = 0; j < yesterdayArr[0].length; j++) {
          if (yesterdayArr[i][j] !== '') {
            map.set('yesterday_grid_' + i + '_' + j, yesterdayArr[i][j])
          }
        }
      }
      setYesterdayMap(map)
    }
  }, [yesterdayArr])

  function max (v1, v2) {
    if (v1 > v2) {
      return v1
    } else {
      return v2
    }
  }

  useEffect(() => {
      let realWords = []
      let theWords = getWords()
      for (var theWord of theWords) {
        if (dataSet.wordSet.has(theWord.toUpperCase())) {
          realWords.push(theWord)
        }
      }
      // console.log("start", theWords, realWords)
      setReal(realWords)

      // if (realWords.length > 0) {
      //   let realWords2 = realWords.map(val => val.split('').join('.'))
      //   var board = getArr()
      //   for (var rows of board) {
      //     let row = rows.join('.')
      //     console.log(row)
      //     for (var wor of realWords2) {
      //       if (row.includes(wor)) {
      //         console.log(wor.split('.').join(''))
      //       }
      //     }
      //   }
      //   for (var rows of transposeBoard(board)) {
      //     let row = rows.join('.')
      //     console.log(row)
      //     for (var wor of realWords2) {
      //       if (row.includes(wor)) {
      //         console.log(wor.split('.').join(''))
      //       }
      //     }
      //   }
      // }

    setScore(getScoreCount())
  }, [charsInGrid])

  useEffect(() => {
    const updateData = async score => {
      logEvent(analytics, 'finished_squeeze')
      const docRef = doc(db, 'Scores', todayId)
      const docs = await getDoc(docRef)
      if (docs.exists()) {
        let high = docs.data().HighScore
        if (high && score >= high) {
          setHighscore(high)
          setDoc(
            docRef,
            { players: increment(1), scores: arrayUnion(score) },
            { merge: true }
          )
        } else {
          setHighscore(score)
          setDoc(
            docRef,
            {
              HighScore: score,
              players: increment(1),
              scores: arrayUnion(score),
              bestSqueeze: JSON.stringify(getArr2())
            },
            { merge: true }
          )
        }
        setPlayers(docs.data().players + 1)
      } else {
        setPlayers(players + 1)
        if (score < highscore) {
          setHighscore(score)
        }
      }
    }
    if (won) {
      updateData(score)
    }
  }, [won])

  useEffect(() => {
    setWordIsGood(false)
    if (charsInTest.length > 1) {
      let arr = []
      for (var i = 0; i < numrows; i++) {
        if (testMap.has('testMap_grid_' + 0 + '_' + i)) {
          arr.push(testMap.get('testMap_grid_' + 0 + '_' + i)[0])
        } else {
          arr.push('')
        }
      }
      var checkTest = arr
        .map(val => val[0])
        .join(' ')
        .split('  ')
        .filter(val => val !== ' ' && val !== '')
      if (checkTest.length === 1) {
        let w = checkTest[0].split(' ').join('')
        if (dataSet.wordSet.has(w.toUpperCase())) {
          setWordIsGood(true)
        }
      }
    }
  }, [charsInTest])

  return (
    <div className='Game'>
      <DndContext onDragEnd={handleDragEnd}>
        <div>
          {alert.length === 0 && (
            <>
              {!won && (
                <>
                  {highscore !== 'Unsolved' && (
                    <h3>
                      Played {players} time{players !== 1 ? 's' : ''} today!
                    </h3>
                  )}
                  {highscore === 'Unsolved' && <h3>Unsolved Today</h3>}
                </>
              )}
              {won && (
                <h3>
                  Lowest Score is {highscore} out of {players} Total Games!
                </h3>
              )}
            </>
          )}
          {won && (
            <>
              {score === highscore && (
                <h3 className='win'>
                  {'You have the best and lowest score today! ' +
                    score +
                    ' points!'}
                </h3>
              )}
              {score !== highscore && (
                <h3 className='win'>
                  {'You scored ' + score + ' points! Can you do better?'}
                </h3>
              )}
            </>
          )}
          {alert.length > 0 && <h3 className='lose'>{alert}</h3>}
          <div className={won || checking ? 'Grid disablePointer' : 'Grid'}>
            <Grid map={parentMap} numrows={numrows} numcols={numrows} id='' />
          </div>
        </div>

        {!won && (
          <div>
            <div className='Chars'>
              {chars.map((val, i) => (
                <div key={val + i}>
                  {!charsInGrid.includes(`${val + i}`) &&
                    !charsInTest.includes(`${val + i}`) && (
                      <Draggable id={val + i}>
                        <div className='CharsDiv'>{val}</div>
                      </Draggable>
                    )}
                </div>
              ))}
            </div>

            <div className='buttonGroup'>
              {!checking && (
                <>
                  <button
                    className='won'
                    onClick={checkPuzzle}
                    disabled={charsInGrid.length !== chars.length}
                  >
                    Check Puzzle
                  </button>

                  <button
                    onClick={() => setNumrows(numrows + 1)}
                    disabled={numrows >= maxrows}
                  >
                    <img className='icon' src={ExpandIcon} alt='Expand' />
                  </button>
                  {/* {(charsInGrid.length > 0 || charsInTest > 0) ?  */}
                  <button
                    onClick={clear}
                    disabled={
                      charsInGrid.length === 0 && charsInTest.length === 0
                    }
                  >
                    <img className='icon' src={RefreshIcon} alt='Reset' />
                  </button>
                  <button
                    onClick={() => {
                      let showing = !showWordChecker
                      setShowWordChecker(!showWordChecker)
                      // if (showing) {
                      //   // window.scrollTo({ top: 500, behavior: 'smooth' })
                      //   window.document.getElementById('backSpace')?.scrollIntoView({ behavior: 'smooth' })
                      // }
                    }}
                  >
                    <img className='icon' src={CheckIcon} alt='Word Checker' />
                  </button>
                  {yesterdayScore !== 0 && (
                    <button onClick={() => setShowYesterday(!showYesterday)}>
                      <img
                        className='icon'
                        src={ChartIcon}
                        alt="Yesterday's Score"
                      />
                    </button>
                  )}
                </>
              )}

              {checking && (
                <h3 onClick={() => setChecking(false)}>Checking...</h3>
              )}
            </div>

            {!checking && showWordChecker && (
              <>
                <h4 className='mt16px'>Spell Check:</h4>

                <div className='game2 keyboards'>
                  <div className='keyboard'>
                    <Keyboard showBackspace={true} noDisable={chars} />
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {won && (
          <div className='buttonGroup'>
            <button className='mt16px won' onClick={continueGame}>
              Continue
            </button>
            <button className='mt16px' onClick={share}>
              Share
            </button>
            {yesterdayScore !== 0 && (
              <button
                className='mt16px'
                onClick={() => setShowYesterday(!showYesterday)}
              >
                <img className='icon' src={ChartIcon} alt="Yesterday's Score" />
              </button>
            )}
            <button className='mt16px' onClick={() => setShowSqueeze(false)}>
              Play Fives
            </button>
          </div>
        )}

        {showYesterday && (
          <>
            <div className='break'></div>
            <div className='mt16px yesterdayScore'>
              <h4>Lowest Score Yesterday: {yesterdayScore}</h4>
              <div className='Grid disablePointer'>
                <Grid
                  map={yesterdayMap}
                  numrows={max(yesterdayArr[0].length, yesterdayArr.length)}
                  numcols={max(yesterdayArr[0].length, yesterdayArr.length)}
                  id='yesterday_'
                />
              </div>
            </div>
          </>
        )}
      </DndContext>
    </div>
  )

  function share () {
    let text =
      "I just completed today's Squeeze with " +
      score +
      ' points! Can you beat me?\n' +
      getShareBoard() +
      ' \ngames.dbcmediaonline.com'
    if (highscore === score) {
      text =
        'I just got the Best Score so far in Squeeze with ' +
        score +
        ' points! Can you beat me?\n' +
        getShareBoard() +
        ' \ngames.dbcmediaonline.com'
    }
    logEvent(analytics, 'shareded_squeeze')
    navigator.share
      ? navigator.share({
          text: text
        })
      : navigator.clipboard.writeText(text) && copyToClipboard()
  }

  function getShareBoard () {
    let arr = getArr()
    let ret = ''
    for (var i = 0; i < arr.length; i++) {
      for (var j = 0; j < arr[0].length; j++) {
        if (arr[i][j] === '') {
          ret += String.fromCodePoint(0x0001f7eb)
        } else {
          ret += String.fromCodePoint(0x0001f7e6)
        }
      }
      ret += '\n'
    }
    ret += ' '
    return ret
  }

  function getWords () {
    let arr = getArr2()
    let ret = []
    const transposedBoard = transposeBoard(arr)
    for (let i = 0; i < arr.length; i++) {
      const words = arr[i]
        .join(' ')
        .split('  ')
        .filter(val => val.length > 0 && val !== ' ')
      for (const word of [...words]) {
        let w = word.split(' ').join('')
        if (w.length > 1) {
          ret.push(w)
        }
      }
    }
    for (let j = 0; j < transposedBoard.length; j++) {
      const words2 = transposedBoard[j]
        .join(' ')
        .split('  ')
        .filter(val => val.length > 0 && val !== ' ')
      for (const word2 of [...words2]) {
        let w2 = word2.split(' ').join('')
        if (w2.length > 1) {
          ret.push(w2)
        }
      }
    }
    return ret
  }

  function transposeBoard (board) {
    return board[0] ? board[0].map((_, col) => board.map(row => row[col])) : []
  }

  async function checkPuzzle () {
    setChecking(true)
    let words = true
    let intersect = false

    let arr = getWords()
    for (var entry in arr) {
      words = words && dataSet.wordSet.has(arr[entry].toUpperCase())
    }

    if (words) {
      await httpsCallable(
        functions,
        'checkConnects'
      )({ grid: JSON.stringify(getArr2()) })
        .then(result => {
          intersect = result.data
        })
        .catch(() => {
          intersect = false
        })
    }

    if (words && intersect) {
      setWon(true)
      setAlert('')
    } else {
      setWon(false)
      setAlert('Words must be legal and intersect')
    }
    setChecking(false)
  }

  function getArr () {
    let arr = []
    for (var i = 0; i < numrows; i++) {
      let subArr = []
      for (var j = 0; j < numrows; j++) {
        if (parentMap.has('grid_' + i + '_' + j)) {
          subArr.push(parentMap.get('grid_' + i + '_' + j)[0])
        } else {
          subArr.push('')
        }
      }
      arr.push(subArr)
    }
    return arr
  }

  function getArr2 () {
    let arr = []
    for (var i = 0; i < numrows; i++) {
      let subArr = []
      for (var j = 0; j < numrows; j++) {
        if (parentMap.has('grid_' + i + '_' + j)) {
          subArr.push(parentMap.get('grid_' + i + '_' + j)[0])
        } else {
          subArr.push('')
        }
      }
      arr.push(subArr)
    }
    
    var j = 0;
    var arr2 = []
    while (j < arr.length && arr[j].join('').length === 0) {
      j++;
    }
    while (j < arr.length) {
      arr2.push(arr[j])
      j++
    }
    j = arr2.length - 1
    while (j > 0 && arr2[j].join('').length === 0) {
      arr2.pop()
      j--
    }
    return arr2
  }

  function getScoreCount () {
    if (charsInGrid.length === 0) {
      return 0
    }
    let arr = getArr()
    let minX = 15
    let maxX = 0
    let minY = 15
    let maxY = 0
    for (var i = 0; i < arr.length; i++) {
      for (var j = 0; j < arr.length; j++) {
        if (arr[i][j].length > 0) {
          if (i < minX) {
            minX = i
          }
          if (i > maxX) {
            maxX = i
          }
          if (j < minY) {
            minY = j
          }
          if (j > maxY) {
            maxY = j
          }
        }
      }
    }
    return (maxX + 1 - minX) * (maxY + 1 - minY)
  }

  function handleDragEnd ({ over, active }) {
    let map = parentMap
    let otherMap = testMap
    let test = over && over.id.includes('testMap')
    if (test) {
      map = testMap
      otherMap = parentMap
    }
    let arr = []
    let otherArr = []
    for (let key of map.keys()) {
      if (map.get(key) === active.id) {
        map.delete(key)
      } else {
        arr.push(map.get(key))
      }
    }
    for (let key of otherMap.keys()) {
      if (otherMap.get(key) === active.id) {
        otherMap.delete(key)
      } else {
        otherArr.push(otherMap.get(key))
      }
    }

    if (over) {
      if (!map.has(over.id)) {
        map.set(over.id, active.id)
        arr.push(active.id)
      }
    }

    if (test) {
      setTestMap(map)
      setCharsInTest(arr)

      setCharsInGrid(otherArr)
      setParentMap(otherMap)
    } else {
      setTestMap(otherMap)
      setCharsInTest(otherArr)

      setCharsInGrid(arr)
      setParentMap(map)
    }
    setAlert('')
  }

  function clear () {
    setCharsInGrid([])
    setParentMap(new Map())
    setNumrows(minrows)
    setScore(0)
    setWon(false)
    setAlert('')
    setTestMap(new Map())
    setCharsInTest([])
  }

  function continueGame () {
    setWon(false)
    setAlert('')
  }

  function shuffleArray (array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1))
      var temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }
}

export function Grid ({ map, numrows, numcols, id }) {
  const rows = []

  let minX = 20;
  let minY = 20;
  let maxX = -1;
  let maxY = -1;
  for (let val of map.keys()) {
    let string = val.split("_");
    let x = parseInt(string[string.length - 1])
    let y = parseInt(string[string.length - 2])
    if (x < minX) {
      minX = x
    }
    if (x > maxX) {
      maxX = x
    }
    if (y < minY) {
      minY = y
    }
    if (y > maxY) {
      maxY = y
    }
  }

  function getClass(i, j) {
    var classVal = 'GridElement'
    if (j >= minX && j <= maxX && i >= minY && i <= maxY) {
      classVal += " grayBox"
    }
    return classVal
  }

  for (let i = 0; i < numrows; i++) {
    const row = []
    for (let j = 0; j < numcols; j++) {
      row.push(
        <div key={id + 'grid_' + i + '_' + j}>
          <Droppable id={id + 'grid_' + i + '_' + j}>
            <div className={getClass(i, j)}>
              {map.has(id + 'grid_' + i + '_' + j) ? (
                <Draggable id={map.get(id + 'grid_' + i + '_' + j)}>
                  <div className='CharsDiv'>
                    {map.get(id + 'grid_' + i + '_' + j)[0]}
                  </div>
                </Draggable>
              ) : null}
            </div>
          </Droppable>
        </div>
      )
    }
    rows.push(
      <div className='GridRow' key={'gridrow_' + i + '_'}>
        {row}
      </div>
    )
  }
  return rows
}

// function BarChart({scores}) {
//   let counts = new Map()
//   let min = scores[0];
//   let max = scores[0]
//   scores.forEach(function (x) {
//     counts.set(x, (counts.get(x) || 0) + 1)
//     x = parseInt(x)
//     if (x < min) {
//       min = x + 1;
//     }
//     if (x > max) {
//       max = x + 1;
//     }
//   });
//   console.log(counts)
//   let data = [["Score", "Count"]]
//   for (var [key, value] of counts) {
//     data.push([key, value])
//   }

//   data.push([min, 0]);
//   data.push([max, 0]);

//   let options = {
//     title: "Score Distribution",
//     hAxis: {
//       title: "Count",
//       minValue: 0,
//     },
//     vAxis: {
//       title: "Score",
//     },
//     legend: { position: "none" },
//   };
//   return <Chart
//       chartType="BarChart"
//       width="100%"
//       height="400px"
//       data={data}
//       options={options}
//     />
// }
