import React, { useState, useEffect } from 'react'
import '../styles/Grid.css'
import BackspaceIcon from '../backspace.svg'

export function Keyboard ({
  changeAction,
  showBackspace = false,
  noDisable = []
}) {
  const keyboard = ['qwertyuiop', 'asdfghjkl', 'zxcvbnm']
  const [word, setWord] = useState('')

  useEffect(() => {setWord('')}, [])

  const dataSet = require('../helpers/wordlist.js')

  function changeWord (val) {
    setWord(word + val)
  }

  function deleteAction () {
    setWord(word.slice(0, -1))
  }

  function countOfLetter (val2) {
    let countInGrid = noDisable.join('').split(val2).length - 1
    let countInWord = word.split(val2).length - 1
    return countInGrid - countInWord
  }

  useEffect(() => {
    function handleKeyDown (e) {
      if (e.key === 'Backspace') {
        deleteAction()
      } else if (noDisable.includes(e.key) && countOfLetter(e.key) > 0) {
        changeWord(e.key)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return function cleanup () {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [word, setWord])

  if (showBackspace) {
    return (
      <>
        {showBackspace && (
          <h4
            className={
              dataSet.wordSet.has(word.toUpperCase()) ? 'goodWord' : 'badWord'
            }
          >
            {word.toUpperCase()}
          </h4>
        )}
        {keyboard.map((val, index) => (
          <div key={val + index}>
            {val.split('').map((val2, index2) => (
              <div key={'row' + index2}>
                <div
                  className={
                    countOfLetter(val2) > 0 ? 'CharsDiv' : 'CharsDiv disable'
                  }
                  onClick={() => changeWord(val2)}
                >
                  {val2}
                  {noDisable.includes(val2) && <p>{countOfLetter(val2)}</p>}
                </div>
              </div>
            ))}

            {showBackspace && index === keyboard.length - 1 && (
              <div id="backSpace">
                <div className='CharsDiv' onClick={deleteAction}>
                  <img className='icon' src={BackspaceIcon} alt='Delete' />
                </div>
              </div>
            )}
          </div>
        ))}
      </>
    )
  } else {
    return (
      <>
        {keyboard.map((val, index) => (
          <div key={val + index}>
            {val.split('').map((val2, index2) => (
              <div key={'row' + index2}>
                <div className='CharsDiv' onClick={() => changeAction(val2)}>
                  {val2}
                </div>
              </div>
            ))}
          </div>
        ))}
      </>
    )
  }
}
