import { Game } from './components/Game'
import { Game2 } from './components/Game2'
import './App.css'
import { Instructions } from './components/Instructions'
import React, { useEffect, useState } from 'react'
import { initializeApp } from 'firebase/app'
import PuzzleLogo from './DBC.png'
import { getAnalytics, logEvent } from "firebase/analytics";

function App () {
  const [score, setScore] = useState(0)
  const [score2, setScore2] = useState(0)
  const [showSqueeze, setShowSqueeze] = useState(true)
  const [showCopy, setShowCopy] = useState(false)
  const [showInstructions, setShowInstructions] = useState(false)
  const [highscore, setHighscore] = useState(0)
  const [highscore2, setHighscore2] = useState(0)

  const app = initializeApp({
    apiKey: 'AIzaSyDyCauWzh31ElfTOmo4j-GLLHbxxwiT7pE',
    authDomain: 'buildings-927de.firebaseapp.com',
    projectId: 'buildings-927de',
    storageBucket: 'buildings-927de.appspot.com',
    messagingSenderId: '1000516783780',
    appId: '1:1000516783780:web:b9173e9b782e826bb56031',
    measurementId: 'G-XCM757E0P7'
  })
  const analytics = getAnalytics(app);

  function copyToClipboard () {
    setShowCopy(true)
    setTimeout(() => {
      setShowCopy(false)
    }, '1000')
  }

  useEffect(() => {
    logEvent(analytics, "loaded_game");
  }, [])

  useEffect(() => {
    logEvent(analytics, "changed_game")
  }, [showSqueeze])

  return (
    <div className='App'>
      <div className='AppHeader'>
        {showSqueeze && (
          <>
            <h3>{score}</h3>
            <div
              className='headerWithLogo'
              onClick={() => setShowSqueeze(false)}
            >
              <img src={PuzzleLogo} alt='DBC Games Logo' />
              <h1>SQUEEZE</h1>
            </div>
          </>
        )}
        {!showSqueeze && (
          <>
            <h3>{score2}</h3>
            <div
              className='headerWithLogo'
              onClick={() => setShowSqueeze(true)}
            >
              <img src={PuzzleLogo} alt='DBC Games Logo' />
              <h1>FIVES</h1>
            </div>
          </>
        )}
        <Instructions squeeze={showSqueeze} show={showInstructions} setShow={setShowInstructions} score={highscore + "." + highscore2} />
      </div>
      {showCopy && (
        <div className='copyToClipboard'>
          <h3>Copied to Clipboard</h3>
        </div>
      )}
      <div className={showSqueeze ? '' : 'displayNone'}>
        <Game
          score={score}
          setScore={setScore}
          app={app}
          setShowSqueeze={setShowSqueeze}
          setHigh={setHighscore}
          copyToClipboard={copyToClipboard}
          analytics={analytics}
        />
      </div>
      <div className={!showSqueeze ? '' : 'displayNone'}>
        <Game2
          score={score2}
          setScore={setScore2}
          app={app}
          setShowSqueeze={setShowSqueeze}
          setHigh={setHighscore2}
          copyToClipboard={copyToClipboard}
          analytics={analytics}
        />
      </div>

      <div className='footer'>
        <a className="mb16px" href='#' onClick={() => setShowInstructions(true)}>How to Play</a>
        <a className="mb16px" href='#' onClick={() => setShowSqueeze(!showSqueeze)}>Switch to {showSqueeze ? 'FIVES' : 'SQUEEZE'}</a>
        <h4>Share Feedback and Send Ideas to <a href="mailto:games@dbcmediaonline.com">games@dbcmediaonline.com</a></h4>
        <a href='https://company.dbcmediaonline.com'><img src={PuzzleLogo} alt='DBC Games Logo' /></a>
      </div>
    </div>
  )
}

export default App
