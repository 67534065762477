import React, { useEffect, useState } from 'react'
import '../styles/Grid.css'
import { Grid } from './Game'
import PuzzleLogo from '../DBC.png';
import CheckIcon from '../check.svg'

export function Instructions ({squeeze, show, setShow, score}) {
  const [map, setMap] = useState(new Map())
  const [map2, setMap2] = useState(new Map())
  const word1 = "DREAM";
  const word2 = "DREAD";
  const word3 = "BREAD";
  const word4 = "BREED";
  const word5 = "ZREED";

  useEffect(() => {
    let tempMap = new Map()
    tempMap.set('grid_1_0', 'H')
    tempMap.set('grid_1_1', 'E')
    tempMap.set('grid_1_2', 'L')
    tempMap.set('grid_1_3', 'L')
    tempMap.set('grid_1_4', 'O')

    tempMap.set('grid_2_4', 'W')
    tempMap.set('grid_2_2', 'O')
    tempMap.set('grid_3_2', 'O')
    tempMap.set('grid_4_2', 'K')
    setMap(tempMap)

    let tempMap2 = new Map()

    tempMap2.set('grid_3_0', 'H')
    tempMap2.set('grid_1_1', 'E')
    tempMap2.set('grid_1_2', 'L')
    tempMap2.set('grid_3_3', 'L')
    tempMap2.set('grid_2_0', 'O')

    tempMap2.set('grid_3_1', 'O')
    tempMap2.set('grid_2_2', 'O')
    tempMap2.set('grid_3_2', 'W')
    tempMap2.set('grid_1_3', 'K')
    setMap2(tempMap2)
  }, [])

  return (
    <div>
      <div className={show ? 'instructions' : 'displayNone'}>
        <div>
          <h3 className='x m0' onClick={() => setShow(false)}>
            X
          </h3>
          {squeeze ? <>
          <h2>HOW TO PLAY SQUEEZE</h2>
          <h3>
            Win by SQUEEZING all the letters in intersecting words in the
            smallest possible area!
          </h3>
          <ol className='left'>
            <li>Use all the letters!</li>
            <li>Make real words!</li>
            <li>Make sure all the words are connected!</li>
            <li>
              Get the LOWEST score possible by using the smallest area in the
              grid!
            </li>
            <li>Add rows if you need space!</li>
            <li className='win'><div className='iconInstructions'>Use the word checker by clicking
                <img
                  className='icon'
                  src={CheckIcon}
                  alt="Word Checker"
                />!</div>
            </li>
            <li>Come back tomorrow for a new puzzle!</li>
          </ol>
          <h3>LOWEST SCORE WINS!</h3>
          <div className='scoreWrap'>
            <div>
              <h3 className='win'>{"Score 12 (3x4)"}</h3>
              <div className='Grid disablePointer'>
                <Grid numrows={5} numcols={5} map={map2} id="" />
              </div>
            </div>
            <div>
              <h3 className='lose'>{"Score 20 (4x5)"}</h3>
              <div className='Grid disablePointer'>
                <Grid numrows={5} numcols={5} map={map} id="" />
              </div>
            </div>
          </div>
          </> : <>
          <h2>HOW TO PLAY FIVES</h2>
          <h3>
            Win by changing one letter at a time and make the most subsequent words in a row!
          </h3>
          <ol className='left'>
            <li>Click on a letter, it will turn green and change it by clicking a letter in the keyboard!</li>
            <li className="win">Submit the word and if it is correct, keep going!</li>
            <li className='lose'>If you guess wrong or you have already entered the word, you will have to start over</li>
            <li>Make real 5-letter words!</li>
            <li>Come back tomorrow for a new puzzle!</li>
          </ol>
          <div className='instructionWord'>
            <h3 className='win'>{"Score: 3 Words (Made 3 Correct Words from Initial Word)"}</h3>
            <div className='actualWord'>
                {word1.split('').map((val, index) => (
                    <div
                    className={index === 4 ? 'CharsDiv active' : 'CharsDiv'}
                    key={"1" + val + index}
                    >
                    {val}
                    </div>
                ))}
                </div>
                <div className='actualWord'>
                {word2.split('').map((val, index) => (
                    <div
                    className={index === 0 ? 'CharsDiv active' : 'CharsDiv'}
                    key={"2" + val + index}
                    >
                    {val}
                    </div>
                ))}
                </div>
                <div className='actualWord'>
                {word3.split('').map((val, index) => (
                    <div
                    className={index === 3 ? 'CharsDiv active' : 'CharsDiv'}
                    key={"3" + val + index}
                    >
                    {val}
                    </div>
                ))}
                </div>
                <div className='actualWord'>
                {word4.split('').map((val, index) => (
                    <div
                    className={index === 0 ? 'CharsDiv active' : 'CharsDiv'}
                    key={"4" + val + index}
                    >
                    {val}
                    </div>
                ))}
                </div>
                <div className='actualWord error'>
                {word5.split('').map((val, index) => (
                    <div
                    className={index === 4 ? 'CharsDiv active' : 'CharsDiv'}
                    key={"5" + val + index}
                    >
                    {val}
                    </div>
                ))}
                </div>
          </div>
          </>}

          <div className='footer'>
            <a href='https://company.dbcmediaonline.com'><img src={PuzzleLogo} alt="DBC Games Logo" /></a>
            {"(" + score + ")"}
          </div>
        </div>
      </div>

      <div>
        <div>
          <h3 className='right m0 mr8px' onClick={() => setShow(true)}>
            ?
          </h3>
        </div>
      </div>
    </div>
  )
}
